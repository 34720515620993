import { render, staticRenderFns } from "./order.vue?vue&type=template&id=ff7d7ce0&scoped=true"
import script from "./order.vue?vue&type=script&lang=js"
export * from "./order.vue?vue&type=script&lang=js"
import style0 from "./order.vue?vue&type=style&index=0&id=ff7d7ce0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff7d7ce0",
  null
  
)

export default component.exports