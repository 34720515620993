<template>
  <div class="user-orders">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h2>My Orders</h2>

    <!-- Tab Navigation -->
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
      <el-tab-pane label="To be paid" name="0"></el-tab-pane>
      <el-tab-pane label="Pending Shipment" name="1"></el-tab-pane>
      <el-tab-pane label="Shipped" name="2"></el-tab-pane>
      <el-tab-pane label="Arrived" name="3"></el-tab-pane>
      <el-tab-pane label="Completed" name="4"></el-tab-pane>
    </el-tabs>

    <div class="orders-grid" v-if="this.orders.length > 0">
      <div v-for="order in orders" :key="order.id" class="order-card">
        <div class="order-status">
          <p>{{ order.createTime }}</p>
        </div>

        <div class="order-image">
          <img :src="order.orderDetailList[0].dynamicGoodsSku.image" alt="Order Image" />
        </div>

        <div class="order-info">
          <p>Order #{{ order.id }}</p>
          <!-- <p>Product: {{ order.productName }}</p> -->
          <!-- <p>Amount: ${{ order.amount }}</p> -->

          <!-- Go to Pay Button for unpaid orders -->
          <button v-if="order.payStatus === 1" @click="goToPay(order.id)">Go to Pay</button>

          <!-- View Order Details button -->
          <button @click="viewOrderDetails(order.id)">View Order Details</button>

          <!-- Request a Refund for Pending Shipment -->
          <button v-if="order.deliverStatus === 1 && order.payStatus === 3" @click="showRefundDialog(order.id)">Request a return for a refund</button>

          <!-- Confirm Order and Review for Arrived Orders -->
          <button v-if="order.deliverStatus === 3" @click="reviewOrder(order.id)">Product Rating</button>

          <!-- Delete button for Completed Orders -->
          <button v-if="order.deliverStatus === 4" @click="deleteOrder(order.id)" class="delete-button">Delete the order</button>
        </div>
      </div>
    </div>

    <el-empty description="Your order is empty" v-else></el-empty>

    <!-- Refund Reason Dialog -->
    <el-dialog title="Refund Reason" :visible.sync="refundDialogVisible">
      <el-input
        type="textarea"
        v-model="refundReason"
        placeholder="Please enter the reason for the refund/return"
        rows="4"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="submitRefundRequest">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getmyOrder, deleteOrderById } from '@/api/cart/cart'
import { getTabOrder, getTabPayOrder } from '@/api/order/order'
export default {
  name: 'UserOrders',
  data () {
    return {
      orders: [],
      activeTab: '1', // 初始默认选中状态为“待发货”
      refundDialogVisible: false,
      refundReason: '',
      selectedOrderId: null,
      currentPage: 1,
      pageSize: 5
    }
  },
  computed: {
  },
  created () {
    this.getmyOrder()
    this.getTabOrder()
  },
  methods: {
    // 获取全部订单
    async getmyOrder () {
      const res = await getmyOrder(this.userid)
      // this.orders = res.data.orders
      console.log('全部订单', res)
    },
    // 获取分页查询订单
    async getTabOrder () {
      console.log(Number(this.activeTab))
      if (this.activeTab === '0') {
        const res = await getTabPayOrder()
        console.log('待支付', res)
        this.orders = res.data
      } else {
        const res = await getTabOrder(Number(this.activeTab))
        console.log('订单分类', res)
        this.orders = res.data
      }
    },
    viewOrderDetails (orderId) {
      this.$router.push('/OrderDetails/' + orderId)
    },
    reviewOrder (orderId) {
      this.$router.push('/ReviewOrder/' + orderId)
    },
    confirmOrder (orderId) {
      this.$message.success('Order confirmed successfully.')
      // Update order status here
    },
    showRefundDialog (orderId) {
      this.selectedOrderId = orderId
      this.refundDialogVisible = true
    },
    async submitRefundRequest () {
      if (!this.refundReason) {
        return this.$message.error('Please enter a reason for the refund/return.')
      } else {
        this.$message.success('The application for return and refund is successful, please wait patiently for the administrator to review it')
        this.refundDialogVisible = false
      }
    },
    async deleteOrder (orderId) {
      const res = await deleteOrderById(orderId)
      if (res.code === 200) {
        this.$message.success('Order deleted successfully.')
        this.orders = this.orders.filter(order => order.id !== orderId)
      } else {
        this.$message.error('Failed to delete the order.')
      }
    },
    handleTabClick (tab) {
      console.log(tab.name)
      this.activeTab = tab.name
      this.getTabOrder()
      this.currentPage = 1 // Reset to first page when tab changes
    },
    handlePageChange (page) {
      this.currentPage = page
    }
  }
}
</script>

<style scoped>
.user-orders {
  padding: 20px;
}

.orders-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.order-image {
  width: 300px;
  height: 200px;
}

.order-image img {
  width: 100%;
  height: 100%;
}

.order-card {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  background-color: #fff;
}

.order-status {
  margin-bottom: 10px;
}

.order-info {
  margin-top: 10px;
}
.order-info p{
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Show ellipsis for overflowed text */
}
button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: black;
  margin-right: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #000000;
}

.delete-button {
  background-color: black;
  color: white;
}

.delete-button:hover {
  /* background-color: darkred; */
  color: white;
  background-color: red;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
::v-deep .el-tabs__item.is-active {
  color: black !important; /* 激活状态下的文字颜色 */
}

::v-deep .el-tabs__active-bar {
  background-color: black !important; /* 进度条颜色 */
}
::v-deep .el-tabs__item.is-active,
::v-deep .el-tabs__item:hover {
  color: black !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .user-orders h2 {
    font-size: 20px;
  }

  .orders-grid {
    flex-direction: column;
    font-size: 16px;
  }

  .order-card {
    width: 100%;
  }

  .order-image {
    width: 400px;
    height: 200px;
  }

  button {
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
