<template>
  <div class="commodity">
    <!-- 返回按钮 -->
    <button class="back-button" @click="goBack">return</button>

    <!-- 页面标题 -->
    <h1 class="page-title">Product Category</h1>

    <!-- Tab 切换 -->
    <div class="tab-container">
      <el-tabs v-model="activeTab" type="card" @tab-click="handleTabClick" class="custom-tabs">
        <el-tab-pane v-for="item in categories" :key="item.id" :label="item.goodsName" :name="item.id">
          <div class="commodity-list">
            <!-- 商品列表 -->
            <transition-group name="fade" tag="div" class="product-container" >
              <el-card
                v-for="product in products"
                :key="product.id"
                class="product-card"

              >
                <img :src="product.mainImageUrl" class="product-image" @click="goshopping(product)"/>
                <div class="product-info"    @click="goshopping(product)">
                  <h3 class="product-name">{{ product.productsName }}</h3>
                </div>
              </el-card>
            </transition-group>
          </div>

          <!-- 分页组件 -->
          <div class="pagination-container">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="filteredProducts.length"
              :page-size="pageSize"
              @current-change="handlePageChange"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getproductslist } from '@/api/products/products'
import { getproductsskulist } from '@/api/classify/classify'
export default {
  name: 'CommodityIndex',
  data () {
    return {
      activeTab: '1', // 默认选中的tab
      categories: [],
      products: [],
      currentPage: 1,
      pageSize: 6 // 每页显示商品数
    }
  },
  computed: {
    filteredProducts () {
      return this.products.filter(product => product.categoryId === this.activeTab)
    },
    paginatedProducts () {
      const start = (this.currentPage - 1) * this.pageSize
      return this.filteredProducts.slice(start, start + this.pageSize)
    }
  },
  created () {
    this.getproductslist()
  },
  methods: {
    goBack () {
      this.$router.go(-1) // 返回上一页
    },
    // 获取商品分类
    async getproductslist () {
      const res = await getproductslist()
      // console.log(res)
      this.categories = res.data
      this.activeTab = this.categories[0].id
      this.getproductsskulist()
    },
    // 切换tab栏
    handleTabClick (tab) {
      console.log(tab.name)
      this.activeTab = tab.name
      this.getproductsskulist()
      this.currentPage = 1 // 切换tab时，重置分页
    },
    async getproductsskulist () {
      const res = await getproductsskulist(this.activeTab)
      console.log(res)
      this.products = res.data.map(item => item.skuProperties)
    },
    goshopping (item) {
      console.log(item)
      this.$router.push(`/Productdetails/${item.id}`)
    },
    handlePageChange (page) {
      this.currentPage = page
    }
  }
}
</script>

<style scoped>
/* 页面标题样式 */
.page-title {
  text-align: center;
  font-size: 28px;
  color: black;
  margin-bottom: 20px;
}

/* Tab 样式 */
.tab-container {
  display: flex;
  justify-content: center; /* 居中对齐 */
}

.custom-tabs {
  max-width: 1200px; /* 限制Tab栏的宽度，确保居中 */
  width: 100%;

}
.custom-tabs .el-tabs__header {
    background-color: #333;
    color: #fff;
    justify-content: center; /* Tab 按钮居中 */
  }
  .el-tabs__item.is-active {
    background-color: #333;
    color: #fff;
  }
  .custom-tabs .el-tabs__item.is-active {
  background-color: #555; /* 选中Tab的背景色 */
  color: #fff; /* 选中Tab的字体颜色 */
}

/* 修改触摸样式 */
.custom-tabs /deep/ .el-tabs__item.is-active,
.custom-tabs /deep/ .el-tabs__item:hover {
  background-color: #555; /* 触摸或选中Tab的背景色 */
  color: #fff; /* 触摸或选中Tab的字体颜色 */
}
/* 商品列表横排展示，居中对齐 */
.commodity-list {
  display: flex;
  justify-content: center;
}

.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 自动调整列数 */
  gap: 20px;
  justify-items: center; /* 居中对齐商品 */
  max-width: 1200px; /* 控制最大宽度 */
  width: 100%; /* 宽度100% */
}

/* 商品卡片样式 */
.product-card {
  width: 100%;
  max-width: 250px;
  background-color: #fff;
  border: none;
  color: black;
  text-align: center;
  /* transition: all 0.3s ease; */
  /* transform: translateY(10px); */
  /* opacity: 0; */
  /* animation: fade-in 0.1s forwards ease-out; */
}

.product-card:hover {
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
}

/* 商品图片样式 */
.product-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* 商品信息样式 */
.product-info {
  padding: 10px;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
  white-space: normal;
}

.product-price {
  font-size: 16px;
  color: #ccc;
}

/* 动画效果
@keyframes fade-in {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* 分页容器 */
.pagination-container {
  text-align: center;
  margin-top: 20px;
}

/* 全局背景样式，突出高端风格 */
body {
  /* background-color: #000; */
}
.back-button {
  position: absolute;
  top: 20px;  /* 距离页面顶部 20px */
  left: 20px; /* 距离页面右边 20px */
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #555;
}

</style>
