import request from '@/utils/request'

// 添加地址
export const Postaddress = (address, city, zipCode, telephone, recipient) => {
  return request.post('/business/user-address/saveRecord', {
    address, city, zipCode, telephone, recipient, addressType: 1
  })
}

// 查询地址列表
export const Seladdress = () => {
  return request.post('/business/user-address/listRecord', {
    id: '',
    addressType: 1
  })
}

// 单个查询地址
export const SelOneaddress = (id) => {
  return request.post('/business/user-address/findOneRecord', {
    id
  })
}

// 修改地址
export const ChangeAddress = (id, address, city, zipCode, telephone, recipient) => {
  return request.post('/business/user-address/updateRecordById', {
    id,
    address,
    city,
    zipCode,
    telephone,
    recipient,
    addressType: 1
  })
}

// 删除地址
export const DelAddress = (id) => {
  return request.post('/business/user-address/deleteRecordById', {
    id
  })
}
