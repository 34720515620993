<template>
  <div class="HeaderInformation">
    <!-- 滚动文字 -->
    <div class="scorlltext">
      <el-carousel height="200px" direction="horizontal" autoplay indicator-position="none" arrow="never">
    <el-carousel-item v-for="item in texts" :key="item">
      <h3 class="medium">{{ item }}</h3>
    </el-carousel-item>
  </el-carousel>
    </div>
    <!-- 标题 -->
    <div class="title">
      <div class="search">
        <el-input
          placeholder="Search"
          v-model="search"
          class="input-with-select"
          @keyup.native.enter="getsearch"
        >
          <template #append>
            <el-button icon="el-icon-search" @click="getsearch"></el-button>
          </template>
        </el-input>
      </div>
      <div class="titlename">
        <a @click="$router.push('/')">FOHUFO</a>
      </div>
      <div class="titleicon">
        <ul>
          <li @click="$router.push('/commodity')"><i class="el-icon-sell"></i></li>
          <li @click="$router.push('/bloglist')">Blog</li>
          <li @click="$router.push('/UserOrders')"><i class="el-icon-takeaway-box"></i></li>
          <li @click="$router.push('/address')"><i class="el-icon-location-outline"></i></li>
          <li @click="$router.push('/userinfo')"><i class="el-icon-user"></i></li>
          <li @click="$router.push('/favorites')"><i class="el-icon-star-off"></i></li>
          <li @click="$router.push('/addcart')"><i class="el-icon-shopping-cart-2"></i></li>
        </ul>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="navbar">
      <ul>
        <li v-for="(item,index) in navbarlist" :key="index"  @click="$router.push('/classify/' + item.tabCode)">{{item.tabName}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getnavabar } from '@/api/home/home'
export default {
  data () {
    return {
      search: '',
      navbarlist: [],
      texts: [
        'Experience the elegance of personalized spaces with our whole-house customization services.',
        'Transform your home with bespoke furniture designs tailored to your lifestyle.',
        'Every corner of your house, uniquely crafted to reflect your style.',
        'From living rooms to kitchens, create spaces that feel distinctly yours.',
        'Our whole-house customization offers unparalleled flexibility and style.',
        'Seamlessly blend functionality and beauty with customized interiors.',
        'Your dream home, brought to life with fully personalized designs.',
        'Maximize every inch of your space with furniture designed just for you.',
        'Achieve ultimate comfort and luxury with our whole-house design solutions.',
        'Unleash the potential of your home with custom-built furniture and interiors.'
      ]

    }
  },
  mounted () {
    this.getnavabar()
  },
  methods: {
    // 获取导航栏列表
    async getnavabar () {
      const res = await getnavabar()
      console.log(res)
      this.navbarlist = res.data
    },
    getsearch () {
      console.log(this.search)
      if (this.search === '') {
        this.$router.push('/SeaechProducts/""')
      } else {
        this.$router.push('/SeaechProducts/' + this.search)
      }
    }

  }
}
</script>

<style scoped>
.scorlltext {
  width: 100%;
  height: 40px;
  background-color: rgb(66, 82, 102);
  overflow: hidden;
}
.el-carousel__item{
  text-align: center;
  height: 40px;
}
.el-carousel__item h3 {
    /* color: #475669; */
    font-size: 14px;
    opacity: 1;
    line-height: 40px;
    margin: 0;
    color: white;
  }

.title {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between; /* 分散对齐 */
  align-items: center; /* 垂直居中对齐 */
  width: 100%;
  height: 110px;
  padding: 0 10px; /* 添加左右内边距 */
}

.search {
  flex: 1; /* 使搜索框自适应宽度 */
  max-width: 500px; /* 设置最大宽度 */
  margin-right: 20px; /* 增加右边距 */
}

.titlename {
  flex: 1; /* 使标题自适应宽度 */
  text-align: center; /* 中心对齐 */
}

.titlename a {
  display: block;
  margin-top: 20px;
  text-decoration: none;
  color: black;
  font-size: 60px; /* 默认字体大小 */
}

.titleicon {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: flex-end; /* 右对齐 */
  align-items: center; /* 垂直居中对齐 */
  flex: 1; /* 使图标自适应宽度 */
  margin-right: 40px; /* 增加右边距 */
}

.titleicon ul {
  display: flex; /* 使用 flexbox 布局 */
  margin-left: 20px; /* 增加左边距 */
}

.titleicon ul li {
  height: 50px;
  line-height: 50px;
  list-style: none;
  font-size: 22px;
  margin-left: 20px;
  cursor: pointer;
}

.titleicon ul li i {
  font-size: 26px;
}

/* 导航栏 */
.navbar {
  border-top: 1px solid rgba(204, 204, 204);
  border-bottom: 1px solid rgba(204, 204, 204);
  width: 100%;
  height: auto; /* 自适应高度 */
  display: flex; /* 使用 flexbox 布局 */
  justify-content: center; /* 居中对齐 */
  flex-wrap: wrap; /* 换行 */
}

.navbar ul {
  display: flex; /* 使用 flexbox 布局 */
  margin: 0; /* 清除默认 margin */
  padding: 0; /* 清除默认 padding */
  flex-wrap: wrap; /* 支持换行 */
  justify-content: center; /* 居中对齐 */
}

.navbar ul li {
  cursor: pointer;
  height: auto; /* 自适应高度 */
  font-weight: bold;
  list-style: none;
  margin: 10px; /* 增加间距 */
  position: relative; /* 为伪元素定位 */
}

.navbar ul li::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 3px;
  bottom: -10px; /* 线条相对列表项的位置 */
  left: 50%;
  background-color: black;
  transition: width 0.3s, left 0.3s; /* 添加过渡效果 */
}

.navbar ul li:hover::before {
  width: 100%; /* 鼠标悬停时宽度变为100% */
  left: 0; /* 左侧对齐 */
}

.navbar ul li a {
  text-decoration: none;
  color: black;
}

/* 添加媒体查询以处理移动设备样式 */
@media (max-width: 768px) {
  .title {
    height: auto; /* 高度自适应 */
    flex-direction: row; /* 在小屏幕上垂直排列 */
    align-items: center; /* 居中对齐 */
  }

  .search {
    width: 100%; /* 全宽 */
    margin-bottom: 10px;
  }

  .titlename {
    width: 100%;
    text-align: center;
  }

  .titlename a {
    font-size: 30px; /* 在小屏幕上减小标题字体大小 */
    margin-top: 10px; /* 调整顶部边距 */
  }

  .titleicon ul {
    flex-direction: row; /* 在小屏幕上水平排列 */
    justify-content: center; /* 居中对齐 */
  }

  .navbar {
    flex-direction: column; /* 在小屏幕上垂直排列 */
    align-items: center; /* 居中对齐 */
  }

  .navbar ul {
    flex-direction: row; /* 在小屏幕上水平排列 */
  }

  .navbar ul li {
    margin: 5px; /* 减少间距 */
  }

  .navbar ul li {
    font-size: 14px; /* 在小屏幕上减小字体大小 */
  }
}
</style>
