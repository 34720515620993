<template>
  <div class="Policy">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h1>Privacy Policy</h1>
    <section>
      <p>This Privacy Policy ("Policy") explains how we collect, use, and protect your personal information when you visit our website (the "Site"). By using the Site, you agree to the terms of this Policy.</p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Your name, email address, phone number, payment information, etc.</li>
        <li><strong>Usage Data:</strong> Information about your interactions with the Site, including IP address, browser type, and browsing history.</li>
        <li><strong>Cookies:</strong> Small data files stored on your device to enhance user experience.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>Your information is used to:</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Process transactions and communicate with you about your account or orders.</li>
        <li>Analyze usage data to optimize the Site's performance.</li>
        <li>Comply with legal requirements and enforce our policies.</li>
      </ul>

      <h2>3. Information Sharing</h2>
      <p>We do not sell or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating the Site, as long as they agree to keep your information confidential.</p>

      <h2>4. Data Security</h2>
      <p>We implement a variety of security measures to protect your personal information. However, please be aware that no method of online transmission or storage is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>You may have rights under applicable laws to access, update, or delete your personal information. You can also opt out of receiving marketing communications from us at any time.</p>

      <h2>6. Changes to This Policy</h2>
      <p>We may update this Policy periodically. Any changes will be posted on this page with an updated revision date. Your continued use of the Site after changes are made will be considered acceptance of the new Policy.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at: <a herf="mailto:modernfurniture1999@bestfurnitureland.com">modernfurniture1999@bestfurnitureland.com</a></p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PolicyIndex'
}
</script>

<style scoped>
.Policy {
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  /* background-color: #f5f7fa; */
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  animation: slideIn 0.8s ease;
}

h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 10px;
}

h2 {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 15px;
}

p {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
}

ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
  color: #95a5a6;
}

a {
  color: #2980b9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Keyframe for slide-in effect */
@keyframes slideIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .Policy {
    padding: 15px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 19px;
  }

  p, ul {
    font-size: 17px;
  }
}
</style>
