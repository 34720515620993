<template>
  <div class="HomeIndex">
    <!-- 在此处使用引入的组件 -->
    <HeaderInformation></HeaderInformation>
    <div class="homeswiper">
      <el-carousel :interval="4000" arrow="always" height="700px">
        <el-carousel-item v-for="(item,index) in bannerlist" :key="index">
          <img :src="item.banner" class="carousel-img" />
        </el-carousel-item>
      </el-carousel>
    </div>

   <!-- 宣传信息 - 点击滑动展示 -->
   <div class="publicity-section">
  <button class="scroll-left" @click="scrollLeft">←</button>
  <div class="card-container">
    <div class="card" v-for="(item, index) in publicityItems" :key="index">
      <h4>{{ item.title }}</h4>
      <!-- <p>{{ item.description }}</p> -->
      <img :src="item.img" :alt="item.alt"  />
    </div>
  </div>
  <button class="scroll-right" @click="scrollRight">→</button>
</div>

    <!-- 动态渲染分类 -->
    <div v-for="(group, index) in classifiedNavbarList" :key="index"
     :class="(index % 2 === 0) ? 'HomeClassification' : 'HomeClassificationtwo'">
      <ul>
        <li v-for="item in group" :key="item.id"  @click="$router.push('/classify/' + item.tabCode)">
          <span>{{ item.tabName }}</span>
          <img :src="item.tabImageUrl" alt="">
        </li>
      </ul>
    </div>

    <!-- 引导购物网站 -->
    <div class="Guided">
    <div class="guided-container">
      <img class="guided-bg" :src="require('@/img/banner/banner1.png')" alt="background image">
      <div class="guided-content">
        <h2 class="guided-text">Discover Our Exclusive Products</h2>
        <button class="guided-btn" @click="redirectToSubsite">Shop Now</button>
      </div>
    </div>
  </div>

    <BottomInformation></BottomInformation>
  </div>
</template>

<script>
import BottomInformation from '@/components/BottomInformation.vue'
import HeaderInformation from '@/components/HeaderInformation.vue'
import { getnavabar, getbanner } from '@/api/home/home'
export default {
  name: 'HomeIndex',
  components: {
    BottomInformation,
    HeaderInformation
  },
  data () {
    return {
      imglist: [
        require('@/img/banner/banner1.png'),
        require('@/img/banner/banner2.png'),
        require('@/img/banner/banner3.png'),
        require('@/img/banner/banner4.png')
      ],
      navbarlist: [], // 导航栏列表
      bannerlist: [], // 轮播图列表
      publicityItems: [
        { title: 'High-end furniture customization', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Globally renowned team', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'High-end custom style', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Ingenuity and luxury customization', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Exclusive home and private space', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Extreme craftsmanship and individual design', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Exquisite handicraft, high-end quality', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' },
        { title: 'Extraordinary taste, customized exclusivity', description: 'Compare all models.', img: require('@/img/banner/banner1.png'), alt: 'Custom Furniture' }
      ]
    }
  },
  created () {
    this.getnavabar()
    this.getbanner()
  },
  computed: {
    classifiedNavbarList () {
      const result = []
      const { length } = this.navbarlist

      for (let i = 0; i < length; i += 5) {
        result.push(this.navbarlist.slice(i, i + 3)) // 第一排3个
        if (i + 3 < length) {
          result.push(this.navbarlist.slice(i + 3, i + 5)) // 第二排2个
        }
      }

      return result
    }
  },
  methods: {
    // 获取导航栏列表
    async getnavabar () {
      const res = await getnavabar()
      // console.log(res)
      this.navbarlist = res.data
    },
    // 获取轮播图
    async getbanner () {
      const res = await getbanner()
      // console.log(res)
      this.bannerlist = res.data
    },
    scrollLeft () {
      const container = document.querySelector('.card-container')
      container.scrollBy({
        left: -300, // 调整为每次滚动的像素值
        behavior: 'smooth'
      })
    },
    scrollRight () {
      const container = document.querySelector('.card-container')
      container.scrollBy({
        left: 300, // 调整为每次滚动的像素值
        behavior: 'smooth'
      })
    },
    redirectToSubsite () {
      window.location.href = 'https://shop.fohufo.com/' // Replace with your subdomain link
    }
  }

}
</script>

<style scoped>
.Guided {
  width: 100%;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guided-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.guided-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(2px); /* 模糊效果 */
  opacity: 0.7; /* 使图片半透明 */
}

.guided-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.guided-text {
  font-size: 36px;
  margin-bottom: 20px;
}

.guided-btn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.guided-btn:hover {
  background-color: #0056b3;
}
/* 头部宣传 */
.publicity-section {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.card-container {
  display: flex;
  overflow: hidden;
  width: 90%;
  scroll-behavior: smooth;
}

.card {
  width: 300px; /* 卡片宽度 */
  margin: 0 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex-shrink: 0; /* 防止卡片缩小 */
}

.card img {
  width: 300px;
  height: 150px;
  border-radius: 8px;
}
.el-dialog img {
  width: 100%;
}
button {
  background-color: #ccc;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

button:hover {
  background-color: #bbb;
}

/* 首页轮播 */
.homeswiper {
  margin-top: 10px;
  width: 1910px;
  height: 700px;
}

.custom-carousel {
  width: 100%;
  height: 100%;
}

.el-carousel__item {
  height: 700px;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
/* 轮播图按钮样式调整 */
.el-carousel .el-carousel__arrow {
  top: 20%; /* 调整顶部距离，向上移动按钮 */
  width: 40px; /* 调整按钮宽度 */
  height: 40px; /* 调整按钮高度 */
  background-color: rgba(255, 255, 255, 0.7); /* 添加背景颜色，增强可见性 */
  border-radius: 50%; /* 圆形按钮 */
  display: flex; /* 使用 flexbox 布局 */
  align-items: center; /* 垂直居中对齐 */
  justify-content: center; /* 水平居中对齐 */
  z-index: 10; /* 确保按钮在前面 */
}

/* 修改箭头的图标大小 */
.el-carousel .el-carousel__arrow i {
  font-size: 20px; /* 调整图标大小 */
}
.hometitle {
  margin-bottom: 50px;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 34px;
}

/* 首页分类 */
.HomeClassification {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 120px;
  width: 1700px;
  height: 430px;
}
.HomeClassification ul {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between; /* 平均分配项目之间的空间 */
}
.HomeClassification ul li {
  list-style: none;
  width: 500px;
  height: 430px;
}
.HomeClassification ul li span {
  display: block;
  width: 500px;
  height: 50px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px solid rgba(204, 204, 204);
}
.HomeClassification ul li img {
  width: 500px;
  height: 350px;
}

/* 首页2张 */
.HomeClassificationtwo {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 120px;
  width: 1700px;
  height: 430px;
}
.HomeClassificationtwo ul {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between; /* 平均分配项目之间的空间 */
}
.HomeClassificationtwo ul li {
  list-style: none;
  width: 746px;
  height: 430px;
  margin-right: 30px;
}
.HomeClassificationtwo ul li span {
  display: block;
  width: 746px;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px solid rgba(204, 204, 204);
}
.HomeClassificationtwo ul li img {
  width: 746px;
  height: 350px;
}
/* 响应式样式 */
@media (max-width: 768px) {
  .homeswiper {
  margin-top: 10px;
  width: 1930px;
  height: 700px;
}

.custom-carousel {
  width: 100%;
  height: 100%;
}

.el-carousel__item {
  height: 700px;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
  .hometitle {
  margin-bottom: 50px;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 34px;
}

/* 首页分类 */
.HomeClassification {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0px;
  width: 1700px;
  height: 430px;
}
.HomeClassification ul {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between; /* 平均分配项目之间的空间 */
}
.HomeClassification ul li {
  list-style: none;
  width: 400px;
  height: 430px;
}
.HomeClassification ul li span {
  display: block;
  width: 500px;
  height: 50px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px solid rgba(204, 204, 204);
}
.HomeClassification ul li img {
  width: 500px;
  height: 350px;
}

/* 首页2张 */
.HomeClassificationtwo {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0px;
  width: 1700px;
  height: 430px;
}
.HomeClassificationtwo ul {
  display: flex; /* 使用 flexbox 布局 */
  justify-content: space-between; /* 平均分配项目之间的空间 */
}
.HomeClassificationtwo ul li {
  list-style: none;
  width: 746px;
  height: 430px;
  margin-right: 30px;
}
.HomeClassificationtwo ul li span {
  display: block;
  width: 746px;
  height: 50px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  border-bottom: 1px solid rgba(204, 204, 204);
}
.HomeClassificationtwo ul li img {
  width: 746px;
  height: 350px;
}
/* 宣传 */
.card h4{
  font-size: 16px;
}
}
</style>
