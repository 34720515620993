<template>
  <div class="classify">
    <HeaderInformation></HeaderInformation>
    <!-- 面包屑导航 -->
    <nav class="breadcrumb">
      <a href="/">Back to Home</a>
    </nav>
    <!-- 分类标题 -->
    <div class="classifytitle">
      You can make your own design by clicking on the renderings
    </div>
    <!-- 效果图 -->
    <div class="classifyitem" v-if="this.classifylist.length > 0">
      <ul>
        <li v-for="(item, index) in classifylist" :key="index" :class="getItemClass(index)" @click="$router.push('/Scenography/'+item.id)">
          <div class="itemimg">
            <img :src="item.detailImageUrl" alt="">
          </div>
          <div class="itemtext">{{ item.remark }}</div>
        </li>
      </ul>
    </div>
    <el-empty description="There is no customization of renderings, please contact the management" v-else></el-empty>

<!-- 引导购物网站 -->
<div class="guided-purchase fade-in" ref="guidedPurchase">
  <div class="guided-content">
    <h2 class="slide-left">Explore Our Custom Furniture Categories</h2>
    <p class="slide-right">Find the perfect piece to complete your home. Visit our shop now!</p>
    <button @click="goToSubdomain" class="cta-button">Shop by Category</button>
  </div>
</div>

  </div>
</template>

<script>
import HeaderInformation from '@/components/HeaderInformation.vue'
import { getclassify } from '@/api/classify/classify'

export default {
  name: 'classifyIndex',
  components: {
    HeaderInformation
  },
  data () {
    return {
      id: this.$route.params.id,
      classifylist: [],
      classifyname: ''
    }
  },
  created () {
    this.getclassify()
  },
  mounted () {
    this.createObserver() // 在 created 中调用以确保页面加载时观察器生效
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler (newId) {
        this.id = newId
        this.getclassify()
      }
    }
  },
  methods: {
    async getclassify () {
      const res = await getclassify(this.id)
      this.classifylist = res.data
      console.log(res)
    },
    getItemClass (index) {
      // 计算每排的第几个元素
      const pos = index % 5
      if (pos < 3) {
        return 'width-33'
      } else {
        return 'width-50'
      }
    },
    goToSubdomain () {
      window.location.href = 'https://shop.fohufo.com/collections/office' // 链接到子网站
    },
    createObserver () {
      const options = {
        threshold: 0.5 // Trigger when 50% of the section is in view
      }
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.$refs.guidedPurchase.classList.add('in-view')
          }
        })
      }, options)
      observer.observe(this.$refs.guidedPurchase)
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.id = to.params.id
    this.getclassify()
    next()
  }
}

</script>

<style scoped>
/* 引导购物网站 */
.guided-purchase {
  background: linear-gradient(135deg, #f4f4f4, #e9ecef);
  padding: 40px 20px;
  text-align: center;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(50px);
}

.fade-in {
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.guided-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  animation: slideDown 0.8s ease-in-out;
}

.guided-content p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
}

.guided-content .cta-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.guided-content .cta-button:hover {
  background-color: #2980b9;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}
/* 标题文字 */
.classifytitle {
  margin-top: 20px;
  width: 1910px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
}
.classifyitem {
  width: 1700px;
  margin: auto;
  margin-bottom: 50px;

}
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  box-sizing: border-box;
}
.width-33 {
  width: calc(33.33% - 20px);
}
.width-50 {
  width: calc(50% - 20px);
}
.itemimg img {
  width: 500px;
  height: 350px;
  object-fit: cover;
  /* background-color: #3498db; */
}
.itemtext {
  text-align: center;
  margin-top: 10px;
  word-wrap: break-word;
  width: 100%;
}

/* 面包屑导航 */
.breadcrumb {
  margin: 20px 120px;
  font-size: 16px;
}
.breadcrumb a {
  color: #3498db;
  text-decoration: none;
}
.breadcrumb a:hover {
  text-decoration: underline;
}
.breadcrumb span {
  color: #666;
}
/* 媒体查询，适应更小屏幕 */
@media (max-width: 768px) {
  .itemimg img {
  width: 350px;
  height: 250px;
  object-fit: cover;
  /* background-color: #3498db; */
}
  .classifytitle {
    font-size: 16px; /* 更小屏幕时字体变大 */
  }
  .itemtext {
    font-size: 16px; /* 更小屏幕时字体变大 */
  }
  .breadcrumb {
    font-size: 16px; /* 更小屏幕时字体变大 */
  }
}
</style>
