<template>
  <div class="order-details">
    <el-page-header @back="$router.back()" title="Return"></el-page-header>
    <h2>Order #{{ order.id }}</h2>
    <!-- <p>Confirmed On: {{ order.confirmTime }}</p> -->
    <div class="order-status">
      <h4>{{ order.status }}</h4>
      <p>Creation time: {{ order.updateTime }}</p>
    </div>

    <!-- Order Summary with Product List -->
    <div class="order-summary">
      <h3>Order Summary</h3>
      <div v-for="item in order.orderDetailList" :key="item.id" class="summary-item">
        <img :src="item.dynamicGoodsSku.image" alt="Product Image" class="product-image" />
        <div class="item-info">
          <p>Name: {{ item.dynamicGoodsSkuName }}</p>
          <!-- <p>Price: {{ item.finalPrice }}</p> -->
          <p>Quantity: {{ item.goodsNumber }}</p>
        </div>
        <!-- <p class="item-total">Total: ${{item.finalPrice}}</p> -->
      </div>
      <!-- <div class="order-total">
        <h4>Order Total: ${{ order.finalTotalPrice }}</h4>
      </div> -->
    </div>

    <!-- Order Details -->
    <div class="order-details-info" v-if="order && order.userAddress">
      <h3>Order Details</h3>
      <p>recipient: {{ order.userAddress.recipient}}</p>
      <p>Phone: {{ order.userAddress.telephone }}</p>
      <p>Shipping Address: {{ order.userAddress.address }}</p>
      <p>zipCode: {{order.userAddress.zipCode}}</p>
      <!-- Additional order details can go here -->
    </div>
  </div>
</template>

<script>
import { Selorder } from '@/api/cart/cart'
export default {
  name: 'OrderDetails',
  data () {
    return {
      orderId: '',
      order: []
    }
  },
  created () {
    this.orderId = this.$route.params.id
    this.Selorder()
  },

  methods: {
    async Selorder () {
      const res = await Selorder(this.orderId)
      console.log(res)
      this.order = res.data
    }
  }
}
</script>

<style scoped>
.order-details {
  padding: 20px;
}

.order-status,
.order-summary,
.order-details-info {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background-color: #fff;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.summary-item img{
width: 200px;
height: 200px;
}
.product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.item-info {
  flex: 1;
  margin-left: 10px;
}

.item-total {
  font-weight: bold;
  align-self: center;
}

.order-total {
  margin-top: 20px;
  text-align: right;
  font-size: 1.2em;
  font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
  .order-details {
  padding: 20px;
  font-size: 16px;
}

.order-status,
.order-summary,
.order-details-info {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.item-info {
  flex: 1;
  margin-left: 10px;
}

.item-total {
  font-weight: bold;
  align-self: center;
}

.order-total {
  margin-top: 20px;
  text-align: right;
  font-size: 1.2em;
  font-weight: bold;
}
  .order-details {
    padding: 10px;
  }

  .summary-item {
    flex-direction: column;
    align-items: start;
  }
  .summary-item img{
width: 300px;
height: 300px;
}
  .product-image {
    margin-bottom: 10px;
  }

  .order-total {
    text-align: left;
  }
}
</style>
