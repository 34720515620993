import { render, staticRenderFns } from "./SeaechProducts.vue?vue&type=template&id=38612cdc&scoped=true"
import script from "./SeaechProducts.vue?vue&type=script&lang=js"
export * from "./SeaechProducts.vue?vue&type=script&lang=js"
import style0 from "./SeaechProducts.vue?vue&type=style&index=0&id=38612cdc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38612cdc",
  null
  
)

export default component.exports