<template>
  <div class="product-search-page">
    <!-- 返回箭头 -->
    <div class="back-arrow" @click="goBack">
      <el-button icon="el-icon-arrow-left"></el-button>
    </div>

    <!-- 搜索栏 -->
    <el-input
      v-model="searchQuery"
      placeholder="Search for products"
      class="search-input"
      @keyup.enter="searchProducts"
    >
      <template #append>
        <el-button icon="el-icon-search" @click="searchProducts"></el-button>
      </template>
    </el-input>

    <!-- 商品数量显示 -->
    <p class="product-count">Found {{ filteredProducts.length }} products</p>

    <!-- 价格排序 -->
    <div class="sort-options">
      <el-button
        v-if="products.length > 0"
        class="sort-button"
        @click="toggleSortOrder"
        :icon="sortOrder === 'productsNewPrice_asc' ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
      >
        Sort by price
      </el-button>
    </div>

    <!-- 商品展示列表 -->
    <div class="product-list" v-if="products.length > 0">
      <el-card
        v-for="(product, index) in paginatedProducts"
        :key="index"
        class="product-item"
        shadow="hover"
      >
        <img :src="product.mainImageUrl" alt="product" class="product-image"   @click="$router.push(`/Productdetails/${ product.id}`)"/>
        <div class="product-details"   @click="$router.push(`/Productdetails/${ product.id}`)">
          <p class="product-name">{{ product.productsName }}</p>
          <p class="product-price">${{ product.productsNewPrice }}</p>
        </div>
      </el-card>
</div>

<div v-else>
      <el-empty description="The product you are searching for does not exist at the moment, please contact customer service for more information"></el-empty>
      <div class="suggested-products">
        <h2 style="text-align: center;">You may also like:</h2>
        <div class="product-list">
          <el-card
            v-for="(product, index) in suggestedProducts"
            :key="index"
            class="product-item"
            shadow="hover"
          >
            <img :src="product.mainImageUrl" alt="product" class="product-image"   @click="$router.push(`/Productdetails/${ product.id}`)"/>
            <div class="product-details"   @click="$router.push(`/Productdetails/${ product.id}`)">
              <p class="product-name">{{ product.productsName }}</p>
              <p class="product-price">${{ product.productsNewPrice }}</p>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 分页器 -->
    <el-pagination
      v-if="filteredProducts.length > pageSize"
      background
      layout="prev, pager, next"
      :total="filteredProducts.length"
      :page-size="pageSize"
      @current-change="handlePageChange"
    ></el-pagination>
  </div>
</template>

<script>
import { SearchproductList, selTabproducts } from '@/api/classify/classify'
export default {
  data () {
    return {
      searchName: '',
      searchQuery: '',
      suggestedProducts: [],
      products: [], // 初始商品列表
      sortOrder: 'productsNewPrice_asc', // 默认升序排序
      currentPage: 1,
      pageSize: 8 // 每页显示8个商品
    }
  },
  computed: {
    // 过滤后的商品列表
    filteredProducts () {
      return this.products
        .filter((product) =>
          product.productsName.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          return this.sortOrder === 'productsNewPrice_asc' ? Number(a.price) - Number(b.price) : Number(b.price) - Number(a.price)
        })
    },
    // 分页后的商品列表
    paginatedProducts () {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      return this.filteredProducts.slice(start, end)
    }
  },
  methods: {
    // 查询搜索商品
    async SearchproductList () {
      const sort = []
      sort.push(this.sortOrder)
      const res = await SearchproductList(this.searchName, sort)
      console.log(res)
      this.products = res.data.map(item => ({
        id: item.skuProperties.id,
        mainImageUrl: item.skuProperties.mainImageUrl,
        productsNewPrice: item.skuProperties.productsNewPrice,
        productsName: item.skuProperties.productsName
      }))
      // console.log(this.products)
    },
    // 查询推荐产品
    async selTabproducts () {
      const res = await selTabproducts()
      console.log(res)
      // 对返回的数据进行随机化并限制数量为8个
      const shuffledData = res.data.sort(() => 0.5 - Math.random()).slice(0, 8)
      this.suggestedProducts = shuffledData.map(item => ({
        id: item.skuProperties.id,
        mainImageUrl: item.skuProperties.mainImageUrl,
        productsNewPrice: item.skuProperties.productsNewPrice,
        productsName: item.skuProperties.productsName
      }))
    },
    // 搜索商品
    searchProducts () {
      this.currentPage = 1 // 每次搜索时重置到第一页
    },
    // 切换价格排序顺序
    toggleSortOrder () {
      this.sortOrder = this.sortOrder === 'productsNewPrice_asc' ? 'productsNewPrice_desc' : 'productsNewPrice_asc'
      // console.log(this.sortOrder)
      this.SearchproductList()
    },
    // 处理分页
    handlePageChange (page) {
      this.currentPage = page
    },
    // 返回上一页
    goBack () {
      window.history.back()
    }
  },
  mounted () {
    this.searchName = this.$route.params.id
    this.SearchproductList()
    this.selTabproducts()
  }
}
</script>

<style scoped>
.product-search-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.back-arrow {
  margin-bottom: 20px;
}

.search-input {
  margin-bottom: 20px;
  width: 100%;
}

.product-count {
  font-size: 16px;
  margin-bottom: 20px;
}

.sort-options {
  margin-bottom: 20px;
}

.sort-button {
  margin-bottom: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-item {
  width: calc(25% - 20px); /* 每行4个商品 */
  transition: transform 0.3s;
  text-align: center;
  cursor: pointer;
}

.product-item:hover {
  transform: translateY(-10px); /* 鼠标悬停时的动画效果 */
}

.product-image {
  width: 150px;
  height: 150px;
  height: auto;
  margin-bottom: 10px;
}

.product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-name {
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: bold;
  width: 200px;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}

.product-price {
  color: orange;
  font-size: 16px;
}

.el-pagination {
  margin-top: 20px;
  text-align: center;
}
.suggested-products h2{
    margin: 40px;
  }

/* 添加媒体查询 */
@media (max-width: 1024px) {
  .product-item {
    width: calc(33.33% - 20px); /* 每行3个商品 */
  }
}

@media (max-width: 768px) {
  .product-item {
    width: calc(50% - 20px); /* 每行2个商品 */
  }
  .suggested-products h2{
    font-size: 22px;
    margin: 40px;
  }
}

@media (max-width: 320px) {
  .product-item {
    width:calc(30.33%); /* 每行1个商品 */
  }
}
</style>
