import { render, staticRenderFns } from "./WebsiteTerms.vue?vue&type=template&id=c06155c8&scoped=true"
import script from "./WebsiteTerms.vue?vue&type=script&lang=js"
export * from "./WebsiteTerms.vue?vue&type=script&lang=js"
import style0 from "./WebsiteTerms.vue?vue&type=style&index=0&id=c06155c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c06155c8",
  null
  
)

export default component.exports