import Vue from 'vue'
import VueRouter from 'vue-router'
import ContactUs from '@/views/Contactus/index'
import HomeIndex from '@/views/home/index'
import LoginIndex from '@/views/login/index'
import RegisterIndex from '@/views/login/register'
import WebsiteTerms from '@/views/login/WebsiteTerms'
import PolicyIndex from '@/views/login/Policy'
import UserInfoIndex from '@/views/home/userinfo.vue'
import classifyIndex from '@/views/classify/classify.vue'
import ScenographyIndex from '@/views/Scenography/Scenography.vue'
import ProductdetailsIndex from '@/views/Productdetails/index'
import AddcartIndex from '@/views/addcart/addcart.vue'
import FavoritesIndex from '@/views/addcart/favorites.vue'
import AddressIndex from '@/views/address/address.vue'
import AboutusIndex from '@/views/Contactus/aboutus.vue'
import BlogHomeIndex from '@/views/Blog/Bloglist.vue'
import BlogdetailIndex from '@/views/Blog/Blogdetail.vue'
import OrderIndex from '@/views/order/order'
import commodityIndex from '@/views/Productdetails/commodity.vue'
import UserOrders from '@/views/order/UserOrders'
import OrderDetails from '@/views/order/OrderDetails'
import ReviewOrder from '@/views/order/ReviewOrder'
import SeaechProducts from '@/views/Productdetails/SeaechProducts'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    { path: '/', component: HomeIndex }, // 首页
    { path: '/login', component: LoginIndex }, // 登录页
    { path: '/register', component: RegisterIndex }, // 注册页
    { path: '/WebsiteTerms', component: WebsiteTerms }, // 条款和条件
    { path: '/Policy', component: PolicyIndex }, // 隐私政策
    { path: '/userinfo', component: UserInfoIndex }, // 用户信息
    { path: '/ContactUs', component: ContactUs }, // 联系我们
    { path: '/aboutus', component: AboutusIndex }, // 关于我们
    { path: '/classify/:id', component: classifyIndex }, // 场景分类
    { path: '/Scenography/:id', component: ScenographyIndex }, // 场景设计
    { path: '/Productdetails/:id', component: ProductdetailsIndex }, // 商品详情
    { path: '/addcart', component: AddcartIndex }, // 购物车列表
    { path: '/favorites', component: FavoritesIndex }, // 收藏列表
    { path: '/address', component: AddressIndex }, // 地址列表
    { path: '/bloglist', component: BlogHomeIndex }, // 新闻列表
    { path: '/commodity', component: commodityIndex }, // 商品展示列表
    { path: '/blogdetail/:id', component: BlogdetailIndex }, // 新闻详情
    { path: '/order/:id', component: OrderIndex }, // 创建订单
    { path: '/UserOrders', component: UserOrders }, // 我的订单
    { path: '/OrderDetails/:id', component: OrderDetails }, // 订单详情
    { path: '/ReviewOrder/:id', component: ReviewOrder }, // 订单评价
    { path: '/SeaechProducts/:id', component: SeaechProducts } // 商品搜索
  ]
})

// 全局前置导航守卫
const authUrls = ['/userinfo', '/addcart', '/favorites', '/address', '/UserOrders']
router.beforeEach((to, from, next) => {
  if (!authUrls.includes(to.path)) {
    next()
    return
  }
  const token = store.state.user.token
  if (token) {
    next()
  } else {
    next('/login')
  }
})
export default router
